<template>
  <Forma></Forma>
</template>

<script>
import Forma from "../components/Forma.vue";

export default {
  name: "Formation",
  components: {
    Forma,
  },
};
</script>

<style scoped></style>
